import React from "react";

import * as styles from "../styles/index.module.css"
import Link from 'gatsby-link'
import Layout from "../components/layout"

import { styled } from "@material-ui/core";
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
// import Route
// import { BrowserRouter as Route } from "react-dom";
import { items } from "../interfaces/home-data";

import { Helmet } from "react-helmet";
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));



const Home = () =>
(
    <>
        <Helmet>
            <script src="./assets/vendor/preline/dist/hs-ui.bundle.js" />
        </Helmet>
        <section id='Home'>
            <Box sx={{ flexGrow: 1 }}>

                {/* {console.log(items)} */}
                {items.map((item, index) => (

                    <Grid key={index} container className={styles.grid}  >
                        {item.map(elemento => (


                            <Grid key={"item-" + elemento.id.toString()} item lg={elemento.lg} md={elemento.md || 5} sm={elemento.sm || 5} xs={11} >
                                <Link key={"item-" + elemento.url} to={elemento.url}>
                                    <Item key={elemento.id.toString()} className={styles.gridItem} style={{
                                        backgroundImage: `url("${elemento.urlImg}")`,
                                        maxHeight: "500px",
                                        minHeight: "100px",
                                        height: "25vw",
                                        backgroundPosition: "center",
                                        backgroundSize: "cover",
                                        backgroundRepeat: "no-repeat",
                                        // boxShadow: "inset 0px -140px 55px -25px #9f8a6fe6",
                                        // boxShadow: "inset 0px -6vw 55px -25px #1f407ae6"
                                        display: "flex",
                                        alignItems: "flex-end",
                                        justifyContent: "flex-end",
                                        padding: "0px",
                                        borderRadius: "0px"
                                    }}>
                                        <div className={styles.divGradiente} style={{
                                            background: `linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(0 71 169 / 22%) 22%, rgb(0 71 169 / 70%) 90%)`,
                                            // linear-gradient(rgba(255, 255, 255, 0) 0%, rgb(0 71 169 / 22%) 22%, rgb(0 71 169 / 70%) 90%);
                                        }}>
                                            <p>
                                                <b>{elemento.title}</b> {elemento.text}
                                            </p>
                                            {elemento.price &&
                                                <p className={styles.price}>
                                                    <b>${elemento.price}</b>
                                                </p>
                                            }
                                            {elemento.btn &&
                                                <button>Comprar Ahora</button>
                                            }

                                            {/* <Link to={item.url} > <b>{item.title}</b> {item.text}</Link> */}
                                            {/* <Link to={elemento.url} params={elemento}> <b>{elemento.title}</b> {elemento.text}</Link> */}
                                        </div>


                                    </Item>
                                </Link>
                            </Grid>

                        ))}
                    </Grid>


                ))}
            </Box>
        </section>
    </>
)

export default Home

