import * as React from "react";
import Layout from "../components/layout";
import Helmet from "react-helmet";
import { withPrefix, Link } from "gatsby";

import useItems from "../hooks/useItems";
import Home from "./home";
import SimpleMap from "../components/mapa";
const IndexPage = () => {
const response = useItems();
console.log(response);

  return (
    <React.Fragment>
    <Layout>
      <Helmet>
        <title>Aries Online</title>
        <script src={withPrefix("hs-ui.bundle.js")} type="text/javascript" />
        {/* <script src="./assets/vendor/preline/dist/preline.js"></script> */}
      </Helmet>
     <Home></Home>

        {/* <SimpleMap></SimpleMap> */}

    </Layout>
    </React.Fragment>
  );
};


//import { StaticQuery, graphql } from 'gatsby';

// const query = graphql`
//   query {
//     allStrapiHeader {
//       edges {
//         node {
//           data {
//             id
//             attributes {
//               name
//               description
//             }
//           }
//         }
//       }
//     }
//   }
// `;

// const IndexPage = () => (
//   <StaticQuery
//     query={query}
//     render={data => (
//       <ul>
//         {data.allStrapiHeader.edges[0].node.data.map(restaurant => (
//           <li key={restaurant.id}>{restaurant.attributes.name}</li>
//         ))}
//       </ul>
//     )}
//   />
// );


export default IndexPage;
